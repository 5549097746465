import { Action, AuthField } from './types';
import I, { ImmutableObject } from 'seamless-immutable';
import {
  accountAdapter,
  brandAdapter,
  brandEmailAdapter,
  brandForFilterAdapter,
  brandInListAdapter,
  invitationAdapter,
  userAdapter
} from './adapters';
import {
  addBrand,
  addBrandEmail,
  addCampaignsTemplate,
  addInvitation,
  deleteInvitation,
  deleteTeamMember,
  logout,
  removeBrand,
  removeCampaignTemplate,
  removeOneBrandEmail,
  setAccount,
  setActiveBrand,
  setActiveBrandId,
  setActiveSettingsBrand,
  setActiveSettingsBrandId, setAuthField,
  setBrandEmails,
  setBrandHasCampaignsTrue,
  setBrands,
  setCampaignTemplates,
  setInvitations,
  setPaymentSubscriptionExpiresAt,
  setTeamMembers,
  setUser, updateAccount,
  updateAccountAction,
  updateAccountPlan,
  updateBrand,
  updateBrandInAccount,
  updateCampaignsTemplate,
  updateOneBrandEmail,
  updateTeamMember,
  updateUser, updateUserField
} from './actions';
import { campaignTemplateAdapter } from '../admin/adapters';
import { Brand, BrandEmail, BrandForFilter, CampaignTemplate, User, UserInvitation } from '../../utils/type';

const initialState = I({
  activeBrandId: localStorage.getItem('activeBrandId') ? localStorage.getItem('activeBrandId') : null,
  token: localStorage.getItem('token'),
  refresh_token: localStorage.getItem('refresh_token'),
  refresh_token_expired_at: localStorage.getItem('refresh_token_expired_at'),
  authorized: Boolean(localStorage.getItem('token')),
  user: userAdapter(),
  plans: [],
  teamMembers: [],
  invitations: [],
  brands: [],
  brandEmails: [],
  activeBrand: brandAdapter(),
  activeSettingsBrand: undefined,
  campaignTemplates: [],
  [AuthField.COMPLETED_ACCOUNT_SETUP]: false,
  [AuthField.UNIQUE_VISITORS_COUNT]: 0,
});

const clearState = I({
  activeBrandId: null,
  activeSettingsBrandId: null,
  token: null,
  refresh_token_expired_at: null,
  refresh_token: null,
  authorized: false,
  teamMembers: [],
  plans: [],
  invitations: [],
  brands: [],
  brandEmails: [],
  activeSettingsBrand: undefined,
  user: userAdapter(),
  activeBrand: brandAdapter(),
  campaignTemplates: [],
  [AuthField.COMPLETED_ACCOUNT_SETUP]: false,
  [AuthField.UNIQUE_VISITORS_COUNT]: 0,
});

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case logout.type: {
      return clearState;
    }

    case updateUser.type: {
      return state.set('user', userAdapter(action.payload));
    }

    case updateUserField.type: {
      return state.set('user', userAdapter({ ...state.user, ...action.payload }));
    }

    case setUser.type: {
      return state.merge({
        token: action.payload.accessToken,
        refresh_token_expired_at: action.payload.refresh_token_expired_at,
        refresh_token: action.payload.refresh_token,
        authorized: true,
        user: userAdapter(action.payload),
      });
    }

    case addBrand.type: {
      return state
        .updateIn(['user', 'account', 'brands'], brands => brands.concat([brandAdapter(action.payload)]))
        .updateIn(['user', 'brandsForFilter'], brands => brands.concat([brandForFilterAdapter(action.payload)]));
    }

    case updateAccountAction.type: {
      return state.updateIn(['user', 'account'], account => accountAdapter({...account, ...action.payload}));
    }

    case setBrands.type: {
      return state.set('brands', action.payload.map(brandInListAdapter));
    }

    case setBrandEmails.type: {
      return state.set('brandEmails', action.payload.map(brandEmailAdapter));
    }

    case addBrandEmail.type: { //@ts-ignore
      return state.update('brandEmails', brandEmail => brandEmail.concat([brandEmailAdapter(action.payload)]));
    }

    case updateOneBrandEmail.type: {
      if (action.payload.isDefault) {
        return state.update('brandEmails', brandEmail => brandEmail.map((item: ImmutableObject<BrandEmail>) => item.id !== action.payload.id
          ? { ...item, isDefault: false }
          : brandEmailAdapter(action.payload))
        )
      }

      return state.update('brandEmails', brandEmail => brandEmail.map((item: ImmutableObject<BrandEmail>) => item.id !== action.payload.id ? item : brandEmailAdapter(action.payload)));
    }

    case removeOneBrandEmail.type: {
      return state.update('brandEmails', brandEmail => brandEmail.filter((item: ImmutableObject<BrandEmail>) => item.id !== action.payload));
    }

    case removeBrand.type: {
      return state.updateIn(['user', 'account', 'brands'], brand => brand.filter((item: ImmutableObject<Brand>) => item.id !== action.payload));
    }

    case setAccount.type: {
      return state.set('account', accountAdapter(action.payload));
    }

    case updateAccount.type: {
      return state.update('account', account => accountAdapter({ ...account, ...action.payload }));
    }

    case updateAccountPlan.type: {
      return state.updateIn(['user', 'account'], account => accountAdapter({...account, ...action.payload}));
    }

    case updateBrand.type: {
      return state.set('activeBrand', brandAdapter(action.payload));
    }

    case updateBrandInAccount.type: {
      return state.updateIn(['user', 'account', 'brands'], brands => brands.map((item: ImmutableObject<Brand>) => {
        if (item.id === action.payload.id) return brandAdapter(action.payload);
        return item;
      }));
    }

    case setBrandHasCampaignsTrue.type: {
      return state.updateIn(['user', 'brandsForFilter'], brands => brands.map((item: ImmutableObject<BrandForFilter>) => {
        if (item.id === action.payload) return brandForFilterAdapter({...item.asMutable({ deep: true }), hasCampaigns: true});
        return item;
      }));
    }

    case setPaymentSubscriptionExpiresAt.type: {
      return state.setIn(['user', 'account', 'payment_expires_at'], action.payload);
    }

    case setActiveBrand.type: {
      return state.set('activeBrand', brandAdapter(action.payload));
    }

    case setActiveSettingsBrand.type: {
      return state.set('activeSettingsBrand', brandAdapter(action.payload));
    }

    case setActiveSettingsBrandId.type: {
      return state.set('activeSettingsBrandId', action.payload);
    }

    case deleteInvitation.type: {
      return state
        .updateIn(['invitations', 'items'], items => items.filter((item: UserInvitation) => item.id !== action.payload))
    }

    case deleteTeamMember.type: {
      return state.update('teamMembers', teamMembers => teamMembers.filter((item: User) => item.id !== action.payload));
    }

    case setInvitations.type: {
      return state.set('invitations', action.payload);
    }

    case setTeamMembers.type: {
      return state.set('teamMembers', action.payload.map(userAdapter));
    }

    case updateTeamMember.type: {
      return state.update('teamMembers', teamMembers => teamMembers.map((member: User) => member.id === action.payload.id ? userAdapter(action.payload) : member));
    }

    case addInvitation.type: { //@ts-ignore
      return state
        .updateIn(['invitations', 'items'], items => [...items, invitationAdapter(action.payload)]);
    }

    case setCampaignTemplates.type: {
      return state.set('campaignTemplates', action.payload.map(campaignTemplateAdapter));
    }

    case removeCampaignTemplate.type: {
      return state
        .update('campaignTemplates', templates => templates.filter((template: CampaignTemplate) => template.id !== action.payload));
    }

    case updateCampaignsTemplate.type: {
      return state
        .update('campaignTemplates', templates => templates.map((template: CampaignTemplate) => {
          if (action.payload.id === template.id) {
            return campaignTemplateAdapter(action.payload);
          }
          return template;
        }));
    }

    case addCampaignsTemplate.type: {
      return state //@ts-ignore
        .update('campaignTemplates', templates => templates.concat([campaignTemplateAdapter(action.payload)]));
    }

    case setActiveBrandId.type: {
      localStorage.setItem('activeBrandId', action.payload);
      return state.set('activeBrandId', action.payload);
    }

    case setAuthField.type: {
      if (!action.path) {
        return state;
      }

      return state.set(action.path, action.payload);
    }
    default: return state;
  }
};

export default reducer;
