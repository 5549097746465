import { useEffect } from 'react';
import { UIStoreEnum } from '../../../store/ui/types';
import { useDispatch } from 'react-redux';
import { setUIField } from '../../../store/ui/actions';


export const useCampaignListBackground = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const campaignListBgImage = 9;
    dispatch(setUIField(campaignListBgImage, UIStoreEnum.CAMPAIGN_LIST_IMAGE));
    const image = new Image();
    image.src = `${process.env.REACT_APP_ASSETS_URL}/space${campaignListBgImage}.jpg`;
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
}
