import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { message, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import {
  accountSelector, activeBrandIdSelector,
  authSelector, userSelector,
} from '../store/auth/selectors';
import {
  logout as signOut,
  setAccount,
  setActiveBrand,
  setActiveBrandId,
  setPaymentSubscriptionExpiresAt,
  setUser,
  updateAccountAction,
  updateAccount as updateAccountStore,
  updateUser, updateUserField
} from '../store/auth/actions';
import { BrandForFilter } from '../utils/type';
import { clearCampaigns } from '../store/campaigns/actions';
import * as T from './requestTypes';
import useApi from './useApi';
import addyConfirm from '../components/confirm';
import useIntegration from './useIntegration';
import { hasFullAccess } from '../utils/helpers/hasFullAccess';
import { UpdateAccountPayload } from '../utils/type/account/updateAccountPayload.type';
import { UserRoles } from '../utils/enums';
import { useErrors, ErrorsType } from '../utils/hooks';

interface iAuth {
  logout: () => void;
  errors: ErrorsType;
  login: (data: T.SignInData, from?: string) => void;
  join: (data: T.JoinData) => void;
  updateProfile: (data: T.UpdateProfileData) => void;
  updateAccountCompanyInfo: (data: T.UpdateAccount, silent?: boolean, cb?: () => void) => void;
  updateAccount: (data: UpdateAccountPayload) => void;
  me: (cb?: () => void) => void;
  getAccount: () => void;
  signUp: (data: T.SignUpData) => void;
  setExpiredSubscriptionDate: (expiredAt: string) => void;
  changePassword: (data: T.ChangePasswordData, cb: () => void) => void;
  forgotPassword: (data: T.ForgotPasswordData) => void;
  resetPassword: (data: T.SaveNewPasswordData) => void;
  confirmEmail: (data: T.ConfirmEmailData) => void;
  busy: boolean;
  createHubSpotTicket: (data: T.HubSpotTicketData, cb: () => void) => void;
}

const useAuth = ():iAuth => {
  const dispatch = useDispatch();
  const { clearIntegration } = useIntegration();
  const history = useHistory();
  const auth = useSelector(authSelector);
  const account = useSelector(accountSelector);
  const user = useSelector(userSelector);

  const api = useApi();
  const [ busy, onChangeBusy ] = useState<boolean>(false);
  const { errors, clearErrors, parseErrors } = useErrors();

  const logout = () => {
    dispatch(clearCampaigns());
    dispatch(signOut());
    clearIntegration();
    history.push('/login');
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_token_expired_at');
  };

  const handleLoginResponse = (data: any, redirect?: boolean, from?: string) => {
    dispatch(setUser(data));
    localStorage.setItem('refresh_token', data.refresh_token);
    localStorage.setItem('refresh_token_expired_at', data.refresh_token_expired_at);

    if ([UserRoles.ADMIN, UserRoles.SUPER_ADMIN].includes(data.role)) {
      if (redirect) history.push(from ? from : '/admin/campaigns');
      return;
    }

    const hasAccessToAllBrands = hasFullAccess(data);

    let activeBrand;
    let activeBrandId: number = Number(localStorage.getItem('activeBrandId'));
    if (activeBrandId) {
      activeBrand = data.account[hasAccessToAllBrands ? 'brands' : 'brandsForFilter']?.find((item: BrandForFilter) => item.id === activeBrandId);
    }

    if (!activeBrand) {
      activeBrand = hasAccessToAllBrands ? data.account.brands[0] : data.brandsForFilter[0];
      activeBrandId = activeBrand?.id;
    }

    if (activeBrandId) {
      dispatch(setActiveBrandId(activeBrandId));
      dispatch(setActiveBrand(activeBrand));
      moment.tz.setDefault(activeBrand.timezone);
    }

    if (!data.account.payment_customer_id && data.role === UserRoles.ACCOUNT_OWNER) {
      history.push('/account-settings');
      return;
    }

    if (redirect) {
      history.push(activeBrandId ? (from || '/campaigns') : '/create-brand');
    }
  }

  const login = (data: T.SignInData, from?: string) => {
    clearErrors();
    onChangeBusy(true);
    api.signIn(data)
      .then((response:AxiosResponse) => {
        onChangeBusy(false);
        localStorage.setItem('token', response.data.accessToken);
        handleLoginResponse(response.data, true, from);
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        parseErrors(error, 'Authorization error');
      });
  };

  const signUp = (data: T.SignUpData) => {
    clearErrors();
    onChangeBusy(true);
    api.signUp(data)
      .then((response:AxiosResponse) => {
        onChangeBusy(false);
        localStorage.setItem('token', response.data.accessToken);
        localStorage.setItem('refresh_token', response.data.refresh_token);
        localStorage.setItem('refresh_token_expired_at', response.data.refresh_token_expired_at);
        dispatch(setUser(response.data));
        if ([UserRoles.ADMIN, UserRoles.SUPER_ADMIN].includes(response.data.role)) {
          history.push('/admin/campaigns');
          return;
        }


        const firstBrand = hasFullAccess(response.data) ? response.data.account.brands[0] : response.data.brandsForFilter[0];

        if (!firstBrand?.id && (moment(response.data.account.payment_expires_at).isAfter(moment()) || ['free', 'staff'].includes(response.data.account?.type))) {
          history.push('/create-brand');
        } else {
          if (firstBrand?.id) {
            dispatch(setActiveBrandId(firstBrand?.id));
            dispatch(setActiveBrand(firstBrand));

            if (!response.data.account.payment_customer_id && response.data.role === UserRoles.ACCOUNT_OWNER) {
              history.push('/account-settings');
              return;
            }

            history.push('/campaigns');
          } else {
            history.push('/account-expired');
          }
        }
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        parseErrors(error, 'Authorization error');
      });
  };

  const forgotPassword = (data: T.ForgotPasswordData) => {
    onChangeBusy(true);
    api.forgotPassword(data)
      .then(() => {
        notification.info({
          message: 'Authorization info',
          description: `Email with instructions was sent to ${data.email}`
        });
        onChangeBusy(false);
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        notification.error({
          message: 'Authorization error',
          description: error.response?.data?.message
        });
      });
  };

  const updateProfile = (data: T.UpdateProfileData) => {
    onChangeBusy(true);
    clearErrors();
    api.updateProfile(data)
      .then((response: AxiosResponse) => {
        const correctData = {
          ...response.data.user,
          brandsForFilter: response.data.brandsForFilter
        };
        onChangeBusy(false);
        dispatch(updateUser(correctData));
        message.success('Your profile was successfully updated');
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        parseErrors(error);
      });
  }

  const updateAccount = (data: UpdateAccountPayload) => {
    api.updateAccount(data);
  }

  const updateAccountCompanyInfo = (data: T.UpdateAccount, silent?: boolean, cb?: () => void) => {
    clearErrors();
    onChangeBusy(true);
    api.updateAccountCompanyInfo(data)
      .then((response: AxiosResponse) => {
        dispatch(updateAccountAction({
          ...response.data,
          payment_customer_id: 'monkey_fix',
        }));
        dispatch(updateUserField({
          phone_number: data.phone_number,
        }));

        onChangeBusy(false);
        message.success('Company information was successfully updated');
        if (data.company_name !== account.company_name && !silent) {
          addyConfirm({
            title: 'One moment..',
            content: 'Updating Account title will affect on your Zapier integration. Please do not forget to update this field in the integration settings'
          });
        }
        if (cb) cb();
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        parseErrors(error);
      });
  }

  const resetPassword = (data: T.SaveNewPasswordData) => {
    onChangeBusy(true);
    api.saveNewPassword(data)
      .then(() => {
        notification.info({
          message: 'Authorization info',
          description: 'Password was updated. Please, login'
        });
        onChangeBusy(false);
        history.push('/login');
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        parseErrors(error, 'Authorization error');
      });
  };

  const me = (cb?: () => void) => {
    api.signInByRefreshToken({ refreshToken: auth.refresh_token })
      .then((response: AxiosResponse) => {
        handleLoginResponse(response.data, false);
        if (cb) cb();
      })
      .catch((error: AxiosError) => {
        logout();
        notification.error({
          message: 'Authorization error',
          description: error.response?.data?.message
        });
      });
  };

  const confirmEmail  = (data: T.ConfirmEmailData) => {
    clearErrors();
    onChangeBusy(true);
    api.confirmEmail(data)
      .then(() => {
        clearErrors();
        onChangeBusy(false);
        notification.success({
          message: 'Authorization info',
          description: 'Email was successfully changed'
        });
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        parseErrors(error, 'Authorization error');
      });
  }

  const changePassword = (data: T.ChangePasswordData, cb: () => void) => {
    clearErrors();
    onChangeBusy(true);
    api.changePassword(data)
      .then(() => {
        clearErrors();
        onChangeBusy(false);
        notification.success({
          message: 'Authorization info',
          description: 'Password was successfully changed'
        });
        cb();
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        parseErrors(error, 'Authorization error');
      });
  }

  const join = (data: T.JoinData) => {
    clearErrors();
    onChangeBusy(true);
    api.join(data)
      .then(() => {
        notification.success({
          message: 'Authorization info',
          description: 'Check your email to continue'
        });
        onChangeBusy(false);
        history.push('/join?emailSent=true');
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        parseErrors(error, 'Authorization error');
      });
  }

  const setExpiredSubscriptionDate = (expiredAt: string) => {
    dispatch(setPaymentSubscriptionExpiresAt(expiredAt));
  }

  const getAccount = () => {
    api.getAccount()
      .then((response: AxiosResponse) => {
        dispatch(setAccount(response.data))
      });
  }

  const createHubSpotTicket = (data: T.HubSpotTicketData, cb: () => void) => {
    onChangeBusy(true);
    api.createHubSpotTicket(data)
      .then(() => {
        onChangeBusy(false);
        addyConfirm({
          title: 'Support ticket has been sent!',
          content: 'We will get back to you as soon as possible on the e-mail registered on your account. If you need urgent assistance with any of the campaigns which are currently live then please contact your customer success manager directly.',
          icon: 'mail',
        });
        cb();
      })
      .catch(() => {
        onChangeBusy(false);
      })
  };

  return {
    logout,
    setExpiredSubscriptionDate,
    join,
    login,
    me,
    getAccount,
    errors,
    busy,
    updateAccountCompanyInfo,
    changePassword,
    updateProfile,
    resetPassword,
    forgotPassword,
    signUp,
    confirmEmail,
    createHubSpotTicket,
    updateAccount,
  }
};

export default useAuth;
