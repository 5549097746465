import React, { FunctionComponent, useContext, useMemo, useState } from 'react';
import { take } from 'lodash';
import IOContext, { MessageConfig } from '../../domain/useIO';
import styles from './message.module.css';
import SystemMessage from './systemMessage';
import SystemNotificationMessageElement from './systemNotificationMessage';
import useUserSystemNotifications from '../../domain/notifications/useUserSystemNotifications';
import { SystemNotificationMessage } from '../../utils/type';
import { SystemNotificationStatusTypeEnum, SystemNotificationTypeEnum, UserRoles } from '../../utils/enums';
import SystemNotificationPopup from './systemNotificationPopup';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';
import useBrand from '../../domain/useBrand';
import useCurrentUser from '../../utils/hooks/useCurrentUser';
import useQuery from '../../utils/query';
import Link from '../../components/link';

const LeadsMessages:FunctionComponent = () => {
  const [ showBrandMessage, setShowBrandMessage ] = useState<boolean>(true);
  const { closeMessage, messageConfigs } = useContext(IOContext);
  const location = useLocation();
  const query = useQuery();

  const { notificationsToShow, onCloseNotification } = useUserSystemNotifications();
  const { brand } = useBrand();
  const { user, account } = useCurrentUser();

  const messageSystemNotifications = useMemo(() => {
    return notificationsToShow.filter((item) => item.type === SystemNotificationTypeEnum.MESSAGE)
  }, [notificationsToShow]);

  const popupSystemNotifications = useMemo(() => (
    notificationsToShow.filter((item) => item.type === SystemNotificationTypeEnum.POPUP)
  ), [notificationsToShow]);

  const messages: (MessageConfig | SystemNotificationMessage)[] = useMemo(() => {
    let allMessages = [
      ...messageConfigs,
      ...messageSystemNotifications
    ];

    if (showBrandMessage && user.id && user.role === UserRoles.ACCOUNT_OWNER && brand.default_brand && account.paid && !query.edit_brand) {
      allMessages = [
        {
          id: 0,
          type: SystemNotificationTypeEnum.MESSAGE,
          status: SystemNotificationStatusTypeEnum.WARN,
          link: '',
          buttonText: '',
          text: '',
          element: (
            <p className={styles.brandSlugPopup}>
              You are currently using automatically generated brand name of "{brand.title}".
              <Link className={styles.link} to={`/settings/brand?id=${brand.id}&edit_brand=true`}>Click here</Link>
              to claim your brand name before publishing your campaign
            </p>
          ),
        },
        ...allMessages,
      ]
    }

    return take(allMessages, 3);
  }, [brand.default_brand, brand.id, brand.title, messageConfigs, messageSystemNotifications, query.edit_brand, showBrandMessage, user]);

  const modalMessages = useMemo(() => take(popupSystemNotifications, 1), [popupSystemNotifications]);

  const closeNotification = (id: number) => {
    if (id) {
      onCloseNotification(id);
    } else {
      setShowBrandMessage(false);
    }
  }

  if (location.pathname === '/account-settings') {
    return null;
  }

  return (
    <div className={styles.container}>
      {
        messages.map((item: MessageConfig | SystemNotificationMessage) => item.hasOwnProperty('messageId')
          ? <SystemMessage item={item as MessageConfig} key={(item as MessageConfig).messageId} onClose={closeMessage}/>
          : <SystemNotificationMessageElement item={item as SystemNotificationMessage} key={(item as SystemNotificationMessage).id} onClose={closeNotification}/>
        )
      }
      <div className={cx(styles.overlay, {[styles.show]: modalMessages.length})}/>
      {
        modalMessages.map((item: SystemNotificationMessage) => (
          <SystemNotificationPopup item={item} key={item.id} onClose={onCloseNotification}/>
        ))
      }
    </div>
  )
};

export default LeadsMessages;
